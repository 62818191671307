<template>
    <div class="returns-wrap">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    v-if="list.length > 0 || loading"
                    @load="getList"
            >
                <dl class="flex justify-content-space-between" v-for="item in list" :key="item.id">
                    <dt><img :src="item.userHeadimgurl"></dt>
                    <dd>
                        <div class="name flex justify-content-space-between border-btm">
                            <strong>{{item.userNikeName}}</strong>
                            <i class="color-068bfb" v-if="item.status == 1">开通中</i>
                            <i class="color-ff0000" v-if="item.status == 6">退单</i>
                            <i class="color-61bc74" v-if="item.status == 3">已完成</i>
                        </div>
                        <span>订单日期：{{item.createTime}}</span>
                        <span>订单金额：{{item.priceStr}}</span>
                    </dd>
                </dl>
            </van-list>
            <van-empty v-else image="error" description="暂无收益信息" />
        </van-pull-refresh>
    </div>
</template>

<script>
    import {returns} from "../../assets/api/commission";

    export default {
        data() {
            return {
                loading: false, // 上拉加载数据状态
                finished: false, // 是否可以上拉加载
                refreshing: false, // 下拉属性状态
                list: [],
                query: {page: 1, limit: 10}
            }
        },
        created() {
            this.getList();
        },
        methods: {
            // 下拉刷新
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.refreshing = true;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.query.page = 1
                this.getList();
            },
            // 请求列表
            getList() {
                this.loading = true;
                returns(this.query).then(res => {
                    if (this.query.page == 1) {
                        this.list = res.data.list
                    } else {
                        this.list = [...this.list, ...res.data.list];
                    }
                    if (this.list.length >= res.data.count) {
                        this.finished = true;
                    }
                    this.refreshing = false;
                    this.query.page++;
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .returns-wrap {
        dl {
            background: #fff;
            margin-bottom: 15px;
            padding: 32px;

            dt {
                width: 85px;
                height: 91px;
                border-radius: 10px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            dd {
                width: calc(100% - 109px);

                .name {
                    padding-bottom: 12px;
                }

                strong {
                    font-size: @font-size4;
                    color: @color-191b21;
                    line-height: 36px;
                }

                i {
                    font-size: @font-size1;
                    line-height: 36px;
                }

                span {
                    display: block;
                    color: @color-878787;
                    font-size: @font-size2;
                    margin-top: 16px;
                }
            }
        }
    }
</style>